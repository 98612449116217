import React, { useEffect, useState } from "react";
import classes from "./AddMainServiceModal.module.css";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { addMainServiceApi, addSubServiceApi } from "../../Data/Api";
import { toast } from "react-toastify";
import Loader from "../Loading-States/Loader";
import AddIcon from "@mui/icons-material/Add";

function PrizesSubModal({
  prizesModal,
  closePrizesModal,
  setPricesAddedByUser,
}) {
  const handleClose = () => {
    closePrizesModal();
  };

  const [price, setPrice] = useState("");
  const [pack, setPack] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (prizesModal) {
      setPrice("");
      setPack("");
      setStatus("");
    }
  }, [prizesModal]);

  const handlePriceAdd = () => {
    setPricesAddedByUser((prevValue) => [
      ...prevValue,
      { price, pack, status },
    ]);
    closePrizesModal();
  };
  return (
    <>
      <Modal
        open={prizesModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="price"
              label="Price"
              type="number"
              variant="outlined"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="pack"
              label="Pack"
              value={pack}
              onChange={(e) => setPack(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="status"
              type="number"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="primary"
              onClick={handlePriceAdd}
            >
              Add the Price
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const AddSubServiceModal = ({
  service,
  serviceId,
  addSubServiceModal,
  closeSubServiceModal,
}) => {
  const [prizesModal, setPrizesModal] = useState(false);
  const [prices, setPrices] = useState([]);
  const [pricesAddedByUser, setPricesAddedByUser] = useState([]);

  const [pricesArrayOfObjects, setPricesArrayOfObjects] = useState([]);

  const [serviceName, setServiceName] = useState("");
  const [subServiceName, setSubServiceName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [status, setStatus] = useState("");
  const [packs, setPacks] = useState("");

  useEffect(() => {
    if (addSubServiceModal) {
      setPrices([]);
      setPricesAddedByUser([]);
      setPricesArrayOfObjects([]);
      setServiceName("");
      setSubServiceName("");
      setServiceStatus("");
      setStatus("");
      setPacks("");
    }
    setServiceName(service);
  }, [addSubServiceModal, service]);

  const handlePriceChange = (event) => {
    const {
      target: { value },
    } = event;
    setPrices(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const filteredPrices = pricesAddedByUser?.filter((data) =>
      prices.includes(data["pack"])
    );
    setPricesArrayOfObjects(filteredPrices);
  }, [prices, pricesAddedByUser]);

  console.log({
    serviceName,
    subServiceName,
    serviceStatus,
    status,
    packs,
    pricesArrayOfObjects,
  });

  const closePrizesModal = () => {
    setPrizesModal(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${addSubServiceApi}`,
        {
          mainServiceId: serviceId,
          data: {
            serviceName: serviceName,
            subServiceName: subServiceName,
            serviceStatus: serviceStatus,
            status: status,
            packs: packs,
            prices: pricesArrayOfObjects,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response, "response...");
      toast.success("Sub Service Added Successfully!");
      closeSubServiceModal();
    } catch (error) {
      console.log("error", error);
      toast.error(
        error?.response?.data?.error ||
          error?.response?.message ||
          error?.message
      );
    }
  };

  return (
    <>
      <Modal
        open={addSubServiceModal}
        onClose={closeSubServiceModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.modalStyle}>
          <form onSubmit={submitHandler} className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              InputProps={{
                readOnly: true,
              }}
              value={serviceName}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              variant="outlined"
              value={subServiceName}
              onChange={(e) => setSubServiceName(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceStatus"
              label="Service Status"
              variant="outlined"
              value={serviceStatus}
              onChange={(e) => setServiceStatus(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="status"
              type="number"
              label="status"
              variant="outlined"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              type="number"
              id="packs"
              label="Packs"
              variant="outlined"
              value={packs}
              onChange={(e) => setPacks(e.target.value)}
              sx={{ width: "100%" }}
            />

            <div className={classes.selector_wrapper}>
              <FormControl fullWidth>
                <InputLabel id="country-select-label">Select Prizes</InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-simple-select"
                  label="Select Prizes List"
                  sx={{ width: "100%" }}
                  multiple
                  value={prices}
                  input={<OutlinedInput label="Select Recipients" />}
                  onChange={handlePriceChange}
                >
                  {pricesAddedByUser.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data?.pack}>
                        {data?.pack}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <IconButton
                color="error"
                aria-label="add prizes"
                onClick={() => setPrizesModal(true)}
              >
                <AddIcon />
              </IconButton>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="error"
              sx={{ width: "100%" }}
            >
              Add
            </Button>
          </form>
          <PrizesSubModal
            prizesModal={prizesModal}
            closePrizesModal={closePrizesModal}
            pricesAddedByUser={pricesAddedByUser}
            setPricesAddedByUser={setPricesAddedByUser}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AddSubServiceModal;
