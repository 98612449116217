import React, { useEffect, useState } from "react";
import classes from "./AddMainServiceModal.module.css";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import {
  addMainServiceApi,
  addSubServiceApi,
  addSubServiceDetailsApi,
  updateServiceInfoDetailsApi,
  updateSubServiceDetailsApi,
} from "../../Data/Api";
import { toast } from "react-toastify";
import Loader from "../Loading-States/Loader";
import AddIcon from "@mui/icons-material/Add";

function AddSubServiceDetailsModal({
  addSubServiceDetailsModal,
  closeSubServiceDetailsModal,
  serviceName,
  subServiceName,
  subServiceInfo,
  fetchSubServiceDetails,
}) {
  const handleClose = () => {
    closeSubServiceDetailsModal();
  };

  //   const [serviceName, setServiceName] = useState("");
  //   const [subServiceName, setSubServiceName] = useState("");
  const [database, setDatabase] = useState("");
  const [serviceUrl, setServiceUrl] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [price, setPrice] = useState("");
  const [pack, setPack] = useState("");
  const [frontendpath, setFrontendpath] = useState("");
  const [backendpath, setBackendpath] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [operator, setOperator] = useState("");
  const [empId, setEmpId] = useState("");
  const [serverIp, setServerIp] = useState("");
  const [serviceVideoUrl, setServiceVideoUrl] = useState("");
  const [promotionStartDate, setPromotionStartDate] = useState("");
  const [lastPromotionDate, setLastPromotionDate] = useState("");
  const [promotionUrl, setPromotionUrl] = useState("");
  const [callbackRequest, setCallbackRequest] = useState("");
  const [callbackResponse, setCallbackResponse] = useState("");
  const [chargingStatus, setChargingStatus] = useState("");
  const [chargingApi, setChargingApi] = useState("");
  const [chargingRequest, setChargingRequest] = useState("");
  const [chargingResponse, setChargingResponse] = useState("");
  const [freeTrial, setFreeTrial] = useState("");
  const [freeTrialDays, setFreeTrialDays] = useState("");

  const addSubServiceDetails = async () => {
    try {
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${addSubServiceDetailsApi}`,
        {
          subServiceInfo: subServiceInfo,
          data: {
            database: database,
            serviceUrl: serviceUrl,
            callbackUrl: callbackUrl,
            price: price,
            pack: pack,
            frontendpath: frontendpath,
            backendpath: backendpath,
            description: description,
            country: country,
            operator: operator,
            empId: empId,
            serverIp: serverIp,
            serviceVideoUrl: serviceVideoUrl,
            promotionStartDate: promotionStartDate,
            lastPromotionDate: lastPromotionDate,
            promotionUrl: promotionUrl,
            callbackRequest: callbackRequest,
            callbackResponse: callbackResponse,
            chargingStatus: chargingStatus,
            chargingApi: chargingApi,
            chargingRequest: chargingRequest,
            chargingResponse: chargingResponse,
            freeTrial: freeTrial,
            freeTrialDays: freeTrialDays,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response...");
      fetchSubServiceDetails(subServiceInfo);
      handleClose();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const editSubServiceDetails = async () => {
    try {
      console.log(price, "price");
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${updateSubServiceDetailsApi}`,
        {
          subServiceInfo: subServiceInfo,
          data: {
            database: database,
            serviceUrl: serviceUrl,
            callbackUrl: callbackUrl,
            price: price,
            pack: pack,
            frontendpath: frontendpath,
            backendpath: backendpath,
            description: description,
            country: country,
            operator: operator,
            empId: empId,
            serverIp: serverIp,
            serviceVideoUrl: serviceVideoUrl,
            promotionStartDate: promotionStartDate,
            lastPromotionDate: lastPromotionDate,
            promotionUrl: promotionUrl,
            callbackRequest: callbackRequest,
            callbackResponse: callbackResponse,
            chargingStatus: chargingStatus,
            chargingApi: chargingApi,
            chargingRequest: chargingRequest,
            chargingResponse: chargingResponse,
            freeTrial: freeTrial,
            freeTrialDays: freeTrialDays,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response...");
      fetchSubServiceDetails(subServiceInfo);
      handleClose();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  console.log(addSubServiceDetailsModal, "assd");

  useEffect(() => {
    if (addSubServiceDetailsModal.type === "edit") {
      setDatabase(addSubServiceDetailsModal?.data?.data_base);
      setServiceUrl(addSubServiceDetailsModal?.data?.serviceUrl);
      setCallbackUrl(addSubServiceDetailsModal?.data?.callbackUrl);
      setPrice(addSubServiceDetailsModal?.data?.price);
      setPack(addSubServiceDetailsModal?.data?.pack);
      setFrontendpath(addSubServiceDetailsModal?.data?.frontendpath);
      setBackendpath(addSubServiceDetailsModal?.data?.backendpath);
      setDescription(addSubServiceDetailsModal?.data?.description);
      setCountry(addSubServiceDetailsModal?.data?.country);
      setOperator(addSubServiceDetailsModal?.data?.operator);
      setEmpId(addSubServiceDetailsModal?.data?.empId);
      setServerIp(addSubServiceDetailsModal?.data?.serverIp);
      setServiceVideoUrl(addSubServiceDetailsModal?.data?.serviceVideoUrl);
      setPromotionStartDate(
        addSubServiceDetailsModal?.data?.promotionStartDate
      );
      setLastPromotionDate(addSubServiceDetailsModal?.data?.lastPromotionDate);
      setPromotionUrl(addSubServiceDetailsModal?.data?.promotionUrl);
      setCallbackRequest(addSubServiceDetailsModal?.data?.callbackRequest);
      setCallbackResponse(addSubServiceDetailsModal?.data?.callbackResponse);
      setChargingStatus(addSubServiceDetailsModal?.data?.chargingStatus);
      setChargingApi(addSubServiceDetailsModal?.data?.chargingApi);
      setChargingRequest(addSubServiceDetailsModal?.data?.chargingRequest);
      setChargingResponse(addSubServiceDetailsModal?.data?.chargingResponse);
      setFreeTrial(addSubServiceDetailsModal?.data?.freeTrail);
      setFreeTrialDays(addSubServiceDetailsModal?.data?.freeTrailDays);
    }
    if (addSubServiceDetailsModal.type === "add") {
      setDatabase("");
      setServiceUrl("");
      setCallbackUrl("");
      setPrice("");
      setPack("");
      setFrontendpath("");
      setBackendpath("");
      setDescription("");
      setCountry("");
      setOperator("");
      setEmpId("");
      setServerIp("");
      setServiceVideoUrl("");
      setPromotionStartDate("");
      setLastPromotionDate("");
      setPromotionUrl("");
      setCallbackRequest("");
      setCallbackResponse("");
      setChargingStatus("");
      setChargingApi("");
      setChargingRequest("");
      setChargingResponse("");
      setFreeTrial("");
      setFreeTrialDays("");
    }
  }, [addSubServiceDetailsModal]);

  return (
    <>
      <Modal
        open={addSubServiceDetailsModal?.state}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.modalStyle}>
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              value={serviceName}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              value={subServiceName}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="database"
              label="Database"
              value={database}
              onChange={(e) => setDatabase(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="serviceUrl"
              label="Service Url"
              value={serviceUrl}
              onChange={(e) => setServiceUrl(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="callbackUrl"
              label="Callback Url"
              value={callbackUrl}
              onChange={(e) => setCallbackUrl(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="price"
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="pack"
              label="Pack"
              type="number"
              variant="outlined"
              value={pack}
              onChange={(e) => setPack(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              id="frontendpath"
              label="Frontend Path"
              variant="outlined"
              value={frontendpath}
              onChange={(e) => setFrontendpath(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="backendpath"
              label="Backend Path"
              value={backendpath}
              onChange={(e) => setBackendpath(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="description"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="operator"
              label="Operator"
              variant="outlined"
              value={operator}
              onChange={(e) => setOperator(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="empId"
              label="empId"
              value={empId}
              onChange={(e) => setEmpId(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceVideoUrl"
              label="Service Video Url"
              variant="outlined"
              value={serviceVideoUrl}
              onChange={(e) => setServiceVideoUrl(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="serverIp"
              label="Server Ip"
              variant="outlined"
              value={serverIp}
              onChange={(e) => setServerIp(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              id="promotionUrl"
              label="Promotion Url"
              variant="outlined"
              value={promotionUrl}
              onChange={(e) => setPromotionUrl(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="promotionStartDate"
              label="Promotion Start Date"
              variant="outlined"
              value={promotionStartDate}
              onChange={(e) => setPromotionStartDate(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="lastPromotionDate"
              label="Last Promotion Date"
              variant="outlined"
              value={lastPromotionDate}
              onChange={(e) => setLastPromotionDate(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackRequest"
              label="Callback Request"
              variant="outlined"
              value={callbackRequest}
              onChange={(e) => setCallbackRequest(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackResponse"
              label="Callback Response"
              variant="outlined"
              value={callbackResponse}
              onChange={(e) => setCallbackResponse(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              id="chargingStatus"
              label="Charging Status"
              variant="outlined"
              value={chargingStatus}
              onChange={(e) => setChargingStatus(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingApi"
              label="Charging Api"
              variant="outlined"
              value={chargingApi}
              onChange={(e) => setChargingApi(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingRequest"
              label="Charging Request"
              variant="outlined"
              value={chargingRequest}
              onChange={(e) => setChargingRequest(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingResponse"
              label="Charging Response"
              variant="outlined"
              value={chargingResponse}
              onChange={(e) => setChargingResponse(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrail"
              label="Free Trial"
              variant="outlined"
              value={freeTrial}
              onChange={(e) => setFreeTrial(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrailDays"
              label="Free Trail Days"
              variant="outlined"
              value={freeTrialDays}
              onChange={(e) => setFreeTrialDays(e.target.value)}
              sx={{ width: "100%" }}
            />

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                addSubServiceDetailsModal?.type === "edit"
                  ? editSubServiceDetails()
                  : addSubServiceDetails();
              }}
            >
              {addSubServiceDetailsModal?.type === "edit"
                ? "Edit Sub Service Details"
                : "Add Sub Service Details"}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddSubServiceDetailsModal;
